exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-accessibilita-e-coinvolgere-tutti-js": () => import("./../../../src/pages/projects/accessibilita-e-coinvolgere-tutti.js" /* webpackChunkName: "component---src-pages-projects-accessibilita-e-coinvolgere-tutti-js" */),
  "component---src-pages-projects-disruptive-js": () => import("./../../../src/pages/projects/disruptive.js" /* webpackChunkName: "component---src-pages-projects-disruptive-js" */),
  "component---src-pages-projects-feelo-js": () => import("./../../../src/pages/projects/feelo.js" /* webpackChunkName: "component---src-pages-projects-feelo-js" */),
  "component---src-pages-projects-genero-js": () => import("./../../../src/pages/projects/genero.js" /* webpackChunkName: "component---src-pages-projects-genero-js" */),
  "component---src-pages-projects-hierarchical-clustering-js": () => import("./../../../src/pages/projects/hierarchical-clustering.js" /* webpackChunkName: "component---src-pages-projects-hierarchical-clustering-js" */),
  "component---src-pages-projects-interconnected-js": () => import("./../../../src/pages/projects/interconnected.js" /* webpackChunkName: "component---src-pages-projects-interconnected-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-moodboard-js": () => import("./../../../src/pages/projects/moodboard.js" /* webpackChunkName: "component---src-pages-projects-moodboard-js" */),
  "component---src-pages-projects-opinion-library-js": () => import("./../../../src/pages/projects/opinion-library.js" /* webpackChunkName: "component---src-pages-projects-opinion-library-js" */),
  "component---src-pages-projects-samuele-sanguineti-js": () => import("./../../../src/pages/projects/samuele-sanguineti.js" /* webpackChunkName: "component---src-pages-projects-samuele-sanguineti-js" */),
  "component---src-pages-projects-sign-here-to-fight-the-pandemic-js": () => import("./../../../src/pages/projects/sign-here-to-fight-the-pandemic.js" /* webpackChunkName: "component---src-pages-projects-sign-here-to-fight-the-pandemic-js" */),
  "component---src-pages-projects-the-look-of-love-js": () => import("./../../../src/pages/projects/the-look-of-love.js" /* webpackChunkName: "component---src-pages-projects-the-look-of-love-js" */)
}

